.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.day {
  flex-grow: 1;
  font-size: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dayText {
  font-weight: 800;
  font-size: max(14px, min(10vw, 20vh));
  filter: blur(0.4vw);
  transition-duration: 1500ms;
  transition-property: filter;
  user-select: none;
}

.dayText:hover {
  filter: blur(0.1px);
}

.footer {
  padding: 24px;
  display: flex;
}

.footerIconItem {
  font-size: 24px;
  height: 24px;
}
.footerIconItem:focus {
  outline: none;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.footerIconItem:hover:focus {
  background: none;
}

.progress {
  flex-grow: 1;
  margin: 0 24px;
}

.promo {
  text-align: center;
  margin: 0 auto;
  padding: 18px 32px;
  border-radius: 0 0 8px 8px;
  line-height: 1.4;
  font-size: 14px;
  background: #fff;
  color: #333;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 6px 8px rgba(0, 0, 0, 0.06);
}

.promo h1 {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
}

.promo p {
  margin: 4px 0 3px;
  color: #666;
}

.promoNotice {
  background-color: #efefef;
  color: #444;
  padding: 4px 6px;
  margin: 12px auto 4px;
  border-radius: 3px;
}

.button {
  display: inline-block;
  border: 2px solid;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 5px;
  opacity: 0.8;
  margin-top: 12px;
}

.button:hover {
  opacity: 1;
}
