.popup {
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.swatch {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin: 12px;
  border: 1px solid #efefef;
}
.swatch:focus {
  outline: none;
  box-shadow: 0px 0px 1px 2px #ffffff, 0px 0px 1px 3px #aaa;
}
.popup:hover .swatch:focus {
  box-shadow: none;
}
.popup:hover .swatch:hover {
  box-shadow: 0px 0px 1px 2px #ffffff, 0px 0px 1px 3px #aaa;
}
.swatchSelected,
.swatchSelected:focus,
.popup:hover .swatchSelected,
.popup:hover .swatchSelected:focus,
.popup:hover .swatchSelected:hover {
  box-shadow: 0px 0px 1px 2px #ffffff, 0px 0px 1px 3px #000000;
}
