.menu {
  width: 200px;
  padding: 8px 0;
}

.menuItem {
  display: flex;
  width: 100%;
  padding: 8px 0;
  cursor: pointer;
  color: #222;
}

.menuItem:focus {
  outline: none;
  background-color: #efefef;
}
.menu:hover .menuItem:focus {
  background-color: transparent;
}
.menu:hover .menuItem:hover {
  background-color: #efefef;
}

.menuItemIcon {
  margin-left: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
}

.menuItemLabel {
  min-height: 24px;
  display: flex;
  align-items: center;
}

.separator {
  border-top: 1px solid #efefef;
  height: 1px;
}

.menuItemTitle {
  color: #222;
  font-weight: bold;
  min-height: 24px;
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 4px 0 12px 0;
}
