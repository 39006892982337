.outerBar {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 32px;
  width: 100%;
  height: 24px;
  padding: 4px;
}
.outerBar:hover {
  background-color: rgba(0, 0, 0, 0.25);
}

.outerBarLight {
  background-color: rgba(255, 255, 255, 0.3);
}
.outerBarLight:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.innerBar {
  height: 16px;
  border-radius: 16px 0 0 16px;
  opacity: 0.8;
}
.outerBar:hover .innerBar {
  opacity: 1;
}
