html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-family: "Helvetica Neue", arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  display: inline-block;
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background: transparent;
}

#root {
  height: 100%;
}

/* POPUP STYLING */

.popup-content {
  background: #fff;
  padding: 0;
  margin: 0;
  border-radius: 7px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 6px 8px rgba(0, 0, 0, 0.06);
}

.popup-arrow {
  filter: drop-shadow(0px -4px 2px rgba(0, 0, 0, 0.1));
}

/* Style for background behind an overlay */
.popup-overlay {
}
